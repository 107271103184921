import React from 'react'
import data from '../data/data.json'
import Subscribe from '../components/Subscribe'
import { Link } from 'react-router-dom'

const Catalogues = () => {
  return (
    <div className="mx-auto container  pr-4 pl-4 md:pr-0 md:pl-0">

  <h1 className="text-kintecs font-oswald font-bold text-5xl mt-12">Catalogues</h1>

  {/* Grid Düzeni Mobil Uyumlu Hale Getirildi */}
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-12 gap-y-20 md:gap-y0">
    {data.catalogues.map((catalogues, index) => (
      <div key={index} className="w-full lg:w-64">
        <Link to={`/catalogues/${catalogues.slug}`}>
          <img className="shadow-xl w-full h-auto" src={catalogues.image} alt={catalogues.title} />
          <h2 className="font-bold text-kintecs mt-4 text-lg">{catalogues.title}</h2>
          <h2 className="text-gray-500 text-sm">{catalogues.lang}</h2>
          <button className="bg-kintecs font-oswald rounded-lg py-2 px-4 text-white mt-2 hover:bg-gray-500 w-full md:w-auto">View</button>
        </Link>
      </div>
    ))}
  </div>
  
  <Subscribe />
</div>
  )
}

export default Catalogues