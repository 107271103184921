import React from 'react'
import Productsc from '../components/Products'
import Subscribe from '../components/Subscribe'
import ProjectsComponent from '../components/ProjectsComponent'

const Products = () => {
  return (
    <div className="mx-auto container ">
      <h1 className="text-kintecs font-oswald font-bold text-5xl mt-12 pl-4 md:pl-0">Products</h1>
      <Productsc />

      <h2 className="text-kintecs font-oswald font-bold text-4xl mt-24 pr-4 pl-4 md:pr-0 md:pl-0">All Projects</h2>

      <ProjectsComponent />

      <Subscribe />

    </div>
  )
}

export default Products