import React, { useState, useEffect } from 'react';
import data from '../data/data.json';
import { useParams } from 'react-router-dom';

const Catalogues_Details = () => {
    const { slug } = useParams();

    const catalogues = data.catalogues.find(c => c.slug === slug);
  
    
    if (!catalogues) {
      return <div>Project not catalogues!</div>;
    }
  return (
    <div className="mx-auto container">

        <div className="flex justify-between items-center  mt-12">
        <h1 className="text-kintecs font-oswald font-bold text-5xl">{catalogues.title}</h1>
        <div className="h-full items-center">
            <a target='_blank' href={catalogues.download}>
        <button className="bg-kintecs py-2 px-4 text-white font-oswald font-bold h-full">View/Download Form</button>
            </a>
        </div>
        </div>

        <iframe
            src={catalogues.download}
            width="100%"
            height="600px"
            className="border rounded-lg mt-12 mb-12"
            title="Kinetic Catalogue"
        ></iframe>
    </div>
  )
}

export default Catalogues_Details