import React from 'react'
import Subscribe from '../components/Subscribe'

const Request = () => {
  return (
    <div className="mx-auto container">
         <h1 className="text-kintecs font-oswald font-bold text-5xl mt-12 text-center">Request Form</h1>
        <Subscribe />
    </div>
  )
}

export default Request