import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Footer from './components/Footer';
import Products from './pages/Products';
import Projects from './pages/Projects';
import Videos from './pages/Videos';
import Catalogues from './pages/Catalogues';
import About from './pages/About';
import Contact from './pages/Contact';
import Request from './pages/Request';
import Kufc from './pages/Kufc';
import Kufb from './pages/Kufb';
import Project_Details from './pages/Project_Details';
import Catalogues_Details from './pages/Catalogues_Details';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Header />  
      <Routes>
        <Route path="/" element={<Home />} />  
        <Route path="/products" element={<Products />} /> 
        <Route path="/projects" element={<Projects />} /> 
        <Route path="/videos" element={<Videos />} /> 
        <Route path="/catalogues" element={<Catalogues />} /> 
        <Route path="/about-us" element={<About />} /> 
        <Route path="/contact" element={<Contact />} /> 
        <Route path="/request-form" element={<Request />} /> 
        <Route path="/products/ku-fc" element={<Kufc />} /> 
        <Route path="/products/ku-fb" element={<Kufb />} /> 
        <Route path="/projects/:slug" element={<Project_Details />} />
        <Route path="/catalogues/:slug" element={<Catalogues_Details />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;