import React from 'react'
import data from '../data/data.json'
import { Link } from 'react-router-dom'

const ProjectsComponent = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 lg:gap-x-10 mt-12 gap-y-5 pr-4 pl-4 md:pr-0 md:pl-0">
      
  {data.projects.slice(0, 6).map((projects, index) => (
    <div key={index} className="group">
      <Link to={`/projects/${projects.slug}`}>
        <div className="relative">
          <img
            className="w-full h-[300px] object-cover mt-3 rounded transform transition-transform duration-500 group-hover:scale-105"
            src={projects.pictures[0]}
            alt={projects.projectName}
          />
        </div>
        <h2 className="font-oswald text-xl font-bold mt-3">{projects.projectName}</h2>
        <h3>{projects.location}</h3>
      </Link>
    </div>
  ))}
</div>
  )
}

export default ProjectsComponent