import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="bg-kintecs">
        <div className="mx-auto container">

            <ul className="gap-x-20 text-white font-bold text-3xl pt-24 pb-16 hidden md:flex ">
                <li className=" hover:underline cursor-pointer"><Link to="/products">Products</Link></li>
                <li className=" hover:underline cursor-pointer"><Link to="/projects">Projects</Link></li>
                <li className=" hover:underline cursor-pointer"><Link to="/about-us">About Us</Link></li>
                <li className=" hover:underline cursor-pointer"><Link to="/contact">Contact</Link></li>
            </ul>

            <div className="border-b-[1px] border-gray-500 hidden md:block "></div>

            <div className="flex pt-8 pb-8 gap-x-7 p-4 md:p-0 md:mt-4 md:mb-4">
                <a>
                <img className=" h-9 " src="/assets/brand-libart-w.svg" alt="Libart" />
                </a>
                <a>
                <img className="h-9  " src="/assets/brand-panora-w.svg" alt="Libart" />
                </a>
                <a>
                <img className="h-9  " src="/assets/solaglide-logo-w.svg" alt="Libart" />
                </a>
            </div>

            <div className="border-b-[1px] border-gray-500 ml-4 mr-4 md:ml-0 md:mr-0"></div>

            <div className="md:flex justify-between pt-8 pb-24 ml-4 mr-4 md:ml-0 md:mr-0 md:items-center ">
                <p className="text-xs text-gray-500 text-center">© 2024 All Rights Reserved</p>
                <ul className="gap-x-2 md:gap-x-6 flex justify-center mt-4 md:mt-0">
                    <li><a href="https://www.facebook.com/libartinternational" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-facebook.svg" alt="Libart Facebook" /></a></li>
                    <li><a href="https://twitter.com/Libart" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-twitter.svg" alt="Libart Twitter" /></a></li>
                    <li><a href="https://www.instagram.com/libartinternational/" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-instagram.svg" alt="Libart Instagram" /></a></li>
                    <li><a href="https://www.linkedin.com/company/libartinternational/" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-linkedin.svg" alt="Libart LinkedIn" /></a></li>
                    <li><a href="https://www.pinterest.com/libartcom/" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-pinterest.svg" alt="Libart Pinterest" /></a></li>
                    <li><a href="https://www.youtube.com/@LibartInternational" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-youtube.svg" alt="Libart YouTube" /></a></li>
                    <li><a href="https://vimeo.com/libartinternational" target="_blank"><img className=" bg-white rounded h-6 " src="/assets/social-vimeo.svg" alt="Libart Vimeo" /></a></li>
                </ul>
            </div>

        </div>
    </div>
  )
}

export default Footer