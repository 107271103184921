import React from 'react'

const Subscribe = () => {
  return (
    <div>
        <div className="flex justify-center mt-48 mb-24 p-4 md:p-0">
        <div className="max-w-[460px]">
            <h1 className="font-oswald text-xl font-bold text-kintecs">Subscribe To Get Our News And Blog Updates.</h1>
            <input type="email" placeholder="E Mail Address" class="mt-4 w-full px-3 py-2 border border-kintecs rounded-md focus:outline-none focus:ring-2 focus:ring-gray-600" />
         
         <div class="flex items-center space-x-4 mb-4 mt-4">
            <label class="flex items-center space-x-2">
            <input type="checkbox" class="form-checkbox h-5 w-5 bg-gray-200 border-gray-300 rounded   checked:text-white  accent-kintecs" />
            <span class="text-gray-700 text-sm">News</span>
            </label>
            <label class="flex items-center space-x-2">
            <input type="checkbox" class="form-checkbox h-5 w-5 bg-gray-200 border-gray-300 rounded   checked:text-white  accent-kintecs" />
            <span class="text-gray-700 text-sm">Blog</span>
            </label>
         </div>

         <div class="mb-4 ">
            <label class="flex items-start space-x-2">
            <input type="checkbox" class="form-checkbox h-5 w-5 bg-gray-200 border-gray-300 rounded   checked:text-white  accent-kintecs" />
            <span class="text-gray-700 text-sm">
                <a href="#" class="underline text-kintecs">I agree with the terms of service. My message will be processed according to them.</a>
            </span>
            </label>
         </div>

         <button class="w-full p-3 bg-kintecs text-white font-bold uppercase hover:bg-gray-700 focus:outline-none cursor-pointer">Subscribe</button>

        </div>

    </div>
    </div>
  )
}

export default Subscribe