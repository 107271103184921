import React from 'react';
import { FaPlay } from 'react-icons/fa';
import { useState } from 'react';
import data from '../data/data.json';
import Subscribe from '../components/Subscribe';

const Videos = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null); 

   
    const openModal = (videoUrl) => {
      setCurrentVideo(videoUrl); 
      setIsOpen(true);
    };
  

    const closeModal = () => {
     
      setIsOpen(false);
      setCurrentVideo(null);
    };

  return (
    <div className="mx-auto container  pr-4 pl-4 md:pr-0 md:pl-0">
    <h1 className="text-kintecs font-oswald font-bold text-5xl mt-12">Videos</h1>
    
    {/* Grid Düzeni Mobil Uyumlu Hale Getirildi */}
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 mt-12">
      {data.videos.map((video, index) => (
        <div className="relative" key={index}>
          <div className="w-full h-64 relative flex items-center justify-center mt-9 md:mt-0 ">
            <img
              className="w-full h-full object-cover rounded-lg transform transition-transform duration-500 hover:scale-105 cursor-pointer"
              src={video.thumbnail}
              alt={video.title}
              onClick={() => openModal(video.videoUrl)} 
            />
            <div className="absolute flex items-center justify-center">
              <div className="bg-white bg-opacity-70 p-4 rounded-full">
                <FaPlay className="text-4xl text-gray-800 pl-2" />
              </div>
            </div>
          </div>
  
          <h2 className="text-xl font-oswald font-bold text-libart mt-3">{video.title}</h2>
          <div className="flex justify-between">
            <h3>{video.location}</h3>
            <p className="text-gray-500">{video.projectNumber}</p>
          </div>
        </div>
      ))}
    </div>
  
    {/* Modal (Video Pop-up) */}
    {isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50" onClick={closeModal}>
        <div className="relative bg-white p-6 sm:p-8 rounded-lg max-w-3xl w-full" onClick={(e) => e.stopPropagation()}>
          <button
            className="absolute top-2 right-2 text-white bg-libart rounded-full w-8 h-8 flex items-center justify-center font-black"
            onClick={closeModal}
          >
            X
          </button>
          <div className="w-full h-full">
            {currentVideo && (
              <iframe
                className="w-full aspect-video"
                src={currentVideo} 
                data-mce-fragment="1"
              ></iframe>
            )}
          </div>
        </div>
      </div>
    )}
  
    <Subscribe />
  </div>
  );
};

export default Videos;