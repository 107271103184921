import React from 'react'
import ProjectsComponent from '../components/ProjectsComponent'
import Subscribe from '../components/Subscribe'

const Projects = () => {
  return (
    <div className="mx-auto container">
        <h1 className="text-kintecs font-oswald font-bold text-5xl mt-12 pr-4 pl-4 md:pr-0 md:pl-0">Projects</h1>
        <ProjectsComponent />
        <Subscribe />
    </div>
  )
}

export default Projects