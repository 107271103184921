import React, { useState, useEffect } from 'react';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Autoplay } from 'swiper/modules'; 
import data from '../data/data.json';
import { useParams } from 'react-router-dom';

const Project_Details = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { slug } = useParams();


    useEffect(() => {
      Fancybox.bind("[data-fancybox='gallery']", {
        infinite: false,
      });
  
      return () => {
        Fancybox.destroy();
      };
    }, []);
  
    const toggleReadMore = () => {
      setIsExpanded(!isExpanded);
    };

  
    const project = data.projects.find(p => p.slug === slug);
  
    
    if (!project) {
      return <div>Project not found!</div>;
    }

  

  return (
    <div className="mx-auto container">

            <div className="relative">
            <img className="w-full h-[200px] md:h-[400px] object-cover object-bottom" src={project.banner} alt={project.projectName} />
            <div className="absolute inset-0 bg-gradient-to-t from-kintecs to-transparent opacity-80"></div>
            <div className="absolute bottom-4 left-4 md:bottom-6 md:left-6">
                <h1 className="text-3xl md:text-6xl text-white font-bold font-oswald">{project.projectName}</h1>
            </div>
            </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-2 md:mt-12 mb-6 md:mb-12">
    
   
    <div className="">
        <div className="bg-white p-6 md:shadow-2xl rounded-lg md:border border-gray-500 border-opacity-30 text-kintecs text-sm">

      <h2 className="font-bold text-base">Project Name</h2>
      <p >{project.projectName}</p>
      <h2 className="font-bold mt-2 text-base">Location</h2>
      <p>{project.location}</p>
      <h2 className="font-bold text-base mt-2">Product</h2>
      <p>{project.product}</p>
      <h2 className="font-bold text-base mt-2">Size</h2>
      <p>{project.size}</p>
      <h2 className="font-bold text-base mt-2">Glazing</h2>
      <p>{project.glazing}</p>
      <h2 className="font-bold text-base mt-2">Project Number</h2>
      <p>{project.projectNumber}</p>
        </div>
    </div>

   
        <div className="container mx-auto col-span-3  pr-4 pl-4 md:pr-0 md:pl-0">
            

      <h2 className="text-3xl font-bold mb-4 text-kintecs">
      {project.title}
      </h2>
      
    <div className="relative">

            

      {!isExpanded && (
    <div className="absolute inset-0 bg-gradient-to-t from-white to-transparent opacity-80"></div>
  )}
      {isExpanded ? (
        <>
          <p className="text-lg mb-4 text-kintecs">
          {project.descb}
          </p>

         
        </>
      ) : (
       
        <p className="text-lg  mb-4 text-kintecs ">
            
            {project.descb.length > 700 ? `${project.descb.slice(0, 700)}...` : project.descb}
        </p>
      )}
    </div>

    
    {project.descb && (
  <div className="flex justify-end">
    <button
      onClick={toggleReadMore}
      className="bg-kintecs font-oswald font-bold text-white px-6 py-2 rounded-lg hover:bg-gray-500 transition duration-300"
    >
      {isExpanded ? 'Read Less' : 'Read More'}
    </button>
  </div>
)}
    </div>

  </div>
  <div className="grid grid-cols-2 md:grid-cols-3 gap-4  pr-4 pl-4 md:pr-0 md:pl-0 mt-12 md:mt-0">
  {project.pictures.map((pictures, index) => (
    <a key={index} href={pictures} data-fancybox="gallery">
      <img
        className="w-full md:h-[340px] object-cover rounded transition-transform duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg"
        src={pictures}
        alt={`Image ${index + 1}`}
      />
    </a>
  ))}
</div>

<div className="mx-auto container mt-24 p-4 md:p-0 mb-12 ">
  <div className="flex justify-between">
    <h1 className="font-oswald font-bold text-kintecs md:text-4xl text-3xl">Other Projects</h1>
    <Link to="/projects">
      <button className="text-white bg-kintecs rounded-lg py-3 px-4 md:text-base text-xs font-oswald font-bold">
        View More
      </button>
    </Link>
  </div>
  
  <div className="w-full border-b border-kintecs mt-3"></div>

  <div className="mt-10">
    <Swiper
      breakpoints={{
        140: { slidesPerView: 1, spaceBetween: -40 },
        768: { slidesPerView: 2, spaceBetween: 20 },
        1024: { slidesPerView: 3, spaceBetween: 30 },
        1280: { slidesPerView: 4, spaceBetween: 30 } 
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      speed={5000}
      modules={[Autoplay, Pagination]}
      className="mySwiper"
    >
      {data.projects.slice(0, 6).map((projects, index) => (
        <SwiperSlide key={index}>
          <div className="relative group">
            <img
              className="w-[300px] h-[200px] object-cover mt-3 rounded transform transition-transform duration-500 group-hover:scale-110"
              src={projects.pictures[0]}
              alt={projects.projectName}
            />
          </div>
          <h2 className="font-oswald text-xl font-bold mt-3">{projects.projectName}</h2>
          <h3>{projects.location}</h3>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
</div>
    </div>
  )
}

export default Project_Details