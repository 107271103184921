import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom'
import data from '../data/data.json'
import Subscribe from '../components/Subscribe'
import Country from '../components/Country';

const Kufb = () => {
    const [isOpen, setIsOpen] = useState(false);

   
    const openModal = () => {
      setIsOpen(true);
    };
  

    const closeModal = () => {
      setIsOpen(false);
    };
  return (
    <div className="mx-auto container">

<h1 className="text-kintecs font-oswald font-bold text-5xl mt-12">Portable Unit (KU-FB)</h1>   

<div className="grid grid-cols-2 gap-x-10 mt-12">
    <div>
        <p className="font-bold text-kintecs">Portable with the feature of Flower</p>
        <p className="mt-6">Libart’s Portable Kinetic Telescopic Glass Wind Breaker with a flower box feature is a unique and attractive addition to any deck or balcony. This product has several benefits, making it a great investment for home-owners. It is easy to install and can be easily moved from one location to another. This feature makes it ideal for those who like to rearrange their outdoor space frequently.</p>
        <p className="mt-6">The flower box feature is an excellent way to add a touch of nature and beauty to your outdoor living space. The glass railing provides unrestricted views, making it perfect for those who want to enjoy the outdoors without any obstructions. The glass railing is also durable and requires minimal maintenance, making it a long-term investment.</p>
        <p className="mt-6">Overall, Libart’s Portable Kinetic Telescopic Glass Wind Breaker with a flower box feature is a great addition to any home. It is stylish, and functional, and adds value to your property.</p>
    </div>
    <div className="">
        <img src="../assets/istanbul2.webp" />
        <button onClick={openModal} className="bg-kintecs text-white w-full text-left p-4 mt-4">
            <p className="font-bold font-oswald text-2xl">DOCUMENTS</p>
            <p className="text-sm">Test Reports, Certificates, BIM Objects, Drawings, Documents, Forms</p>
        </button>
        <img className="w-1/2 mx-auto" src="../assets/kufb.webp" />
    </div>
</div>

<div className="border-b border-gray-500 mt-12"></div>


        <div className="flex justify-between mt-24">
            <h1 className="font-oswald font-bold text-kintecs text-2xl">Portable Unit (KU-FB) Portfolios {
    data.projects.filter(project => project.product === "Portable Unit (KU-FB)").length} </h1>
            <Link to="/projects"><button className="text-white bg-kintecs rounded-lg py-3 px-4 text-base font-oswald font-bold">See All Projects</button></Link>
        </div>

        <div className="grid grid-cols-4 gap-x-4 mt-12">
        {data.projects.filter(project => project.product === "Portable Unit (KU-FB)").map((projects, index) => (
                <div key={index} >
                <div className="">
                  <img
                    className="w-full h-[300px]  object-cover mt-3 rounded transform transition-transform duration-500 group-hover:scale-110"
                    src={projects.pictures[0]}
                    alt={projects.projectName}
                  />
                </div>
                <h2 className="font-oswald text-xl font-bold mt-3">{projects.projectName}</h2>
                <h3>{projects.location}</h3>
              </div>
            ))}
        </div>

        <Subscribe />

        {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50" onClick={closeModal}>
          <div className="relative bg-white p-8 rounded-lg max-w-6xl w-full" onClick={(e) => e.stopPropagation()}>
          <button
  className="absolute top-2 right-2 text-white bg-kintecs rounded-full w-10 h-10 flex items-center justify-center font-black"
  onClick={closeModal}
>
  X
</button>
            <div className="w-full h-full">
              
              <div className="flex">
              <div className="w-2/3">

              <div className="container mx-auto mt-8 p-4">
  <h1 className="text-4xl text-kintecs font-bold font-oswald mb-8">Portable Unit (KU-FB) Systems</h1>
  

  <div className="mb-8 text-kintecs">
    <h2 className="font-bold text-lg mb-2 text-kintecs">Request</h2>
    <div className="flex flex-wrap gap-4 items-center">
      <label className="flex items-center">
        <input type="checkbox" className="mr-2  text-kintecs" />
        PDF & Design Documents
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2 text-kintecs" />
        BIM
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2 text-kintecs" />
        Specifications
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2 text-kintecs" />
        Test Reports
      </label>
    </div>
  </div>


  <div className="mb-8 text-kintecs">
    <h2 className="font-bold text-lg mb-2">For Use In</h2>
    <div className="flex flex-wrap gap-4 items-center">
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Hotel & Resort
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Restaurant & Cafe
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Shopping Mall
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        RoofTop Bar, Restaurant
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Leisure & Recreational
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Residential
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Industrial
      </label>
    </div>
  </div>


  <div className="mb-8 text-kintecs">
    <h2 className="font-bold text-lg mb-2">Project Status</h2>
    <div className="flex flex-wrap gap-4 items-center">
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        In Design
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Retrofit
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Under Construction
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Completed
      </label>
    </div>
  </div>


  <div className=" text-kintecs">
    <h2 className="font-bold text-lg mb-2">I am</h2>
    <div className="flex flex-wrap gap-4 items-center">
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Architect / Designer / Specifier
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Owner / User
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Contractor
      </label>
      <label className="flex items-center">
        <input type="checkbox" className="mr-2" />
        Other
      </label>
    </div>
  </div>
</div>
             
                
                </div>
                <div className="w-1/3">

                <div className="w-full  p-6 bg-white border rounded-t-xl shadow-lg">
  <h2 className="font-bold text-lg mb-4 text-kintecs">Contact Information</h2>
  <form>
    <input
      type="text"
      className="w-full border rounded-lg p-3 mb-4"
      placeholder="Your Name & Surname"
    />
    <input
      type="email"
      className="w-full border rounded-lg p-3 mb-4"
      placeholder="E Mail Address"
    />
    <Country />
    <textarea
      className="w-full border rounded-lg p-3 mb-4"
      placeholder="Your Message"
      rows="4"
    ></textarea>
    <p className="text-sm text-gray-500 mb-4">Terms of Service: Libart Project and Application will use the information you provide on this form to be in touch with you and provide updates and marketing. By clicking above, you agree that we may process your information in accordance with privacy terms.</p>
    <div className="flex items-start mb-4">
      <input type="checkbox" className="mt-1 bg-gray-200 border-gray-300 rounded   checked:text-white  accent-kintecs" />
      <label className="ml-2 text-sm text-gray-500">
        I agree with the terms of service. My message will be processed according to them.
      </label>
    </div>
    <button
      type="submit"
      className="w-full p-3 bg-kintecs text-white font-bold rounded-lg hover:bg-gray-500"
    >
      Submit
    </button>
  </form>
</div>
                
              </div>
              </div>

            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Kufb