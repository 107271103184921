import React from 'react'
import { CiLocationOn } from "react-icons/ci";
import { IoMail } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import Country from '../components/Country';




const Contact = () => {
  return (
    <div className="mx-auto container">
      <h1 className="text-kintecs font-oswald font-bold text-5xl mt-12 mx-auto container pr-4 pl-4 md:pr-0 md:pl-0">Contact</h1>

      <div className="w-full border rounded-xl pt-6 pb-6 mt-12  mr-4 ml-4 md:mr-0 md:ml-0">
        <h1 className="flex items-center justify-center font-bold text-kintecs gap-x-2 text-center ">
          <img className="w-5 shadow-2xl" src="assets/flags/canada.webp" alt="Canada Flag" />
          Libart International Office
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center mt-4">
        <CiLocationOn size={20} />
          Vancouver BC V5R 5W2 Canada
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 font-oswald text-lg text-center">
         <a href="https://maps.app.goo.gl/XFaX1zpu3o8deuvj6" target="_blank">
         Show On Map
         </a> 
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
        <IoMail size={20} />
        <a href="to:libartinfo@libart.com" target="_blank">
        libartinfo@libart.com
        </a>
        </h1>

      </div>

      <div className="grid md:grid-cols-2 mt-6 gap-y-6 md:gap-x-6  mr-4 ml-4 md:mr-0 md:ml-0">

      <div className="w-full border rounded-xl pt-6 pb-6">
        <h1 className="flex items-center justify-center font-bold text-kintecs gap-x-2 text-center ">
          <img className="w-5 shadow-2xl" src="assets/flags/usa.webp" alt="Canada Flag" />
          Libart North America, Sales and Manufacturing Stoett Industries, Inc.
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center mt-4">
        <CiLocationOn size={20} />
        600 Defiance Ave., Hicksville, Ohio, 43526
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 font-oswald text-lg text-center">
         <a href="https://maps.app.goo.gl/JLjSBJqQD7WGyXta7" target="_blank">
         Show On Map
         </a> 
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
        <IoCall  size={20} />
        <a href="tel:+1 (800) 431 2986" target="_blank">
        +1 (800) 431 2986 (Toll Free)
        </a>
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
        <IoMail size={20} />
        <a href="to:info@libartusa.com" target="_blank">
        info@libartusa.com
        </a>
        </h1>

      </div>

      <div className="w-full border rounded-xl pt-6 pb-6">
        <h1 className="flex items-center justify-center font-bold text-kintecs gap-x-2 text-center pl-5 pr-5 ">
          <img className="w-5 shadow-2xl" src="assets/flags/uae.webp" alt="Canada Flag" />
          Libart Middle East, Sales and Manufacturing Arki Libart Glass & Aluminum Works L.L.C.
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center mt-4">
        <CiLocationOn size={20} />
        Onyx Tower 1-Sheikh Zayed Road - Office 407 Dubai, UAE
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 font-oswald text-lg text-center">
         <a href="https://maps.app.goo.gl/jimAs9nfKErUhGkb6" target="_blank">
         Show On Map
         </a> 
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
        <IoCall  size={20} />
        <a href="tel:+971 4 252 8903" target="_blank">
        +971 4 252 8903
        </a>
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
        <IoMail size={20} />
        <a href="to:info@arkilibart.com" target="_blank">
        info@arkilibart.com
        </a>
        </h1>

      </div>

      <div className="w-full border rounded-xl pt-6 pb-6 ">
  <h1 className="flex items-center justify-center font-bold text-kintecs gap-x-1 text-center ">
    <img className="w-5 shadow-2xl" src="assets/flags/india.webp" alt="India Flag" />
    Libart East Design, Sales and Manufacturing
  </h1>

  <h1 className="flex justify-center text-kintecs gap-x-1 text-center mt-4 pl-3 pr-3">
    <CiLocationOn size={20} />
    <span className="text-left">805, Aston Building, SunderVan Complex, Shastri Nagar, Andheri (West) Mumbai, Maharashtra 400053, India</span>
  </h1>

  <h1 className="flex items-center justify-center text-kintecs gap-x-1 font-oswald text-lg text-center">
    <a href="https://maps.app.goo.gl/4KhP9CCjSHNDBa6S7" target="_blank" rel="noopener noreferrer">
      Show On Map
    </a> 
  </h1>

  <h1 className="flex items-center justify-center text-kintecs gap-x-1 text-center">
    <IoCall size={20} />
    <a href="tel:+91 86 55816511" target="_blank" rel="noopener noreferrer">
      +91 86 55816511
    </a>
  </h1>

  <h1 className="flex items-center justify-center text-kintecs gap-x-1 text-center">
    <IoCall size={20} />
    <a href="tel:+91 86 55816512" target="_blank" rel="noopener noreferrer">
      +91 86 55816512
    </a>
  </h1>

  <h1 className="flex items-center justify-center text-kintecs gap-x-1 text-center">
    <IoMail size={20} />
    <a href="mailto:sales@libart.in" target="_blank" rel="noopener noreferrer">
      sales@libart.in
    </a>
  </h1>
</div>

      <div className="w-full border rounded-xl pt-6 pb-6 ">
        <h1 className="flex items-center justify-center font-bold text-kintecs gap-x-2 text-center ">
          <img className="w-5 shadow-2xl" src="assets/flags/turkiye.webp" alt="Canada Flag" />
          Libart International Design, Sales and Manufacturing
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center mt-4">
        <CiLocationOn size={20} />
        Mermerciler Sanayi Sitesi, 2. Cad. No 8/1 Istanbul - Turkey
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 font-oswald text-lg text-center">
         <a href="https://maps.app.goo.gl/ygQ4kWJJHnEDhAGX9" target="_blank">
         Show On Map
         </a> 
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
        <IoCall  size={20} />
        <a href="tel:+90 212 879 0265" target="_blank">
        +90 212 879 0265
        </a>
        </h1>

        <h1 className="flex items-center justify-center text-kintecs gap-x-2 text-center">
        <IoMail size={20} />
        <a href="to:libartsales@libart.com" target="_blank">
        libartsales@libart.com
        </a>
        </h1>

      </div>

      </div>

      <div className="grid md:grid-cols-3 mt-12 md:mt-24 pr-4 pl-4 md:pr-0 md:pl-0">


      <div className="col-span-2 h-full">
        <h1 className="text-center md:text-end text-2xl md:text-4xl font-extrabold">WE ARE HERE FOR YOU</h1>
        <h2 className="text-center md:text-end text-xl md:text-3xl font-bold mt-6">With 4 manufacturing facilities, offices,
        more than 40 countries...</h2>
      <img className="w-full" src="assets/map.gif" />
      </div>
      </div>

      <div className="w-full  p-6 bg-white md:border rounded-t-xl shadow-lg">
  <h2 className="font-bold text-lg mb-4 text-kintecs">Contact Us</h2>
  <form>
    <input
      type="text"
      className="w-full border rounded-lg p-3 mb-4"
      placeholder="Your Name & Surname"
    />
    <input
      type="email"
      className="w-full border rounded-lg p-3 mb-4"
      placeholder="E Mail Address"
    />
    <Country />
    <textarea
      className="w-full border rounded-lg p-3 mb-4"
      placeholder="Your Message"
      rows="4"
    ></textarea>
    <div className="flex items-start mb-4">
      <input type="checkbox" className="mt-1 bg-gray-200 border-gray-300 rounded   checked:text-white  accent-kintecs" />
      <label className="ml-2 text-sm">
        I agree with the terms of service. My message will be processed according to them.
      </label>
    </div>
    <button
      type="submit"
      className="w-full p-3 bg-kintecs text-white font-bold rounded-lg hover:bg-gray-500"
    >
      Submit
    </button>
  </form>
</div>
        
    </div>
  )
}

export default Contact