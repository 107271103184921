import React from 'react'
import Subscribe from '../components/Subscribe'

const About = () => {
  return (
    <div className="">
         <h1 className="text-kintecs font-oswald font-bold text-5xl mt-12 mx-auto container  pr-4 pl-4 md:pr-0 md:pl-0">About Us</h1>

         <div className=" bg-kintecs w-full">

            <div className="mx-auto container text-white pt-24 pb-24 mt-12  pr-4 pl-4 md:pr-0 md:pl-0">

                <h1 className="font-oswald text-4xl font-bold">Who We Are</h1>

                <p className="mt-4">Libart is the leading global brand in operable architectural solutions utilizing Aluminum, Glass, and Electromechanical systems. Libart’s retractable structures provide the convenience of achieving indoor or outdoor spaces in the same location within minutes. Libart’s retractable systems are used in; Hotels, Restaurants, Shopping Malls, Recreational Centers, Swimming Pools and large span vertical opening Windows, Doors & Curtain Wall Windows.</p>

                <p className="mt-4">With over <b>30 years of experience</b> in the operable, retractable structures market. Libart <b>exports its products to more than 40 countries.</b> Libart, <b>Headquartered in Vancouver - Canada,</b> we operate through <b>4 Desing, Sales and Manufacturing centers in Ohio - USA, Dubai - UAE, Mumbai - India, and Istanbul - Turkey</b>. Libart is dedicated to supporting architects, consultants, business owners, and contractors in their pursuit of innovative, functional, and well-balanced multipurpose space and architectural designs.</p>
           
                <p className="mt-4">Libart equips architects with the tools that augment their imagination of multi-function spaces and shape environments with natural light. Retractable Structures, glass facade system, retractable skylights/roofs, retractable windows & doors, glass railing windbreaks, and pool enclosures are just a few solutions but our range of services stretch with the modern architectural designs and innovative ideas which are at the helm of Libart.</p>
            
                <h2 className="mt-4 font-oswald text-2xl font-bold">We are working to be your reference source for Motion in Architecture.</h2>
            </div>
         </div>

         <div className="mx-auto container grid grid-cols-1 lg:grid-cols-3 gap-x-16 pb-24 mt-24 items-center  pr-4 pl-4 md:pr-0 md:pl-0">
  <div className="lg:col-span-1 mb-8 lg:mb-0">
    <img className="rounded-lg w-full" src="assets/leaders.webp" alt="Leader" />
  </div>

  <div className="lg:col-span-2">
    <h1 className="font-bold text-kintecs text-4xl">Leader’s Commitment</h1>
    <p className="mt-6">
      Libart’s mission is to provide innovative architectural systems that allow flexible habitation spaces with retractable structures, skylights, and window, and door systems. Libart’s systems and services include site-specific design and customization to best suit project needs, delivery organization, installation engineering, and supervision; and excellent after-sales support for years of problem-free pleasure for your clients.
    </p>
    <p className="mt-6">
      Libart, headquartered in Vancouver, Canada, operates through 4 design, sales, and manufacturing centers located in Ohio, USA; Dubai, UAE; Mumbai, India; and Istanbul, Turkey. With over 30 years of global experience spanning across 40 countries, Libart specializes in operable and retractable architectural systems. Dedicated to supporting architects, consultants, business owners, and contractors, Libart pursues innovative, functional, and well-balanced multipurpose space and architectural designs.
    </p>
    <p className="mt-6 font-bold">Cem ÇOMU</p>
    <p>Founder & CEO</p>
  </div>
</div>

<div className="mx-auto container grid grid-cols-1 lg:grid-cols-3 gap-x-16 pb-24 mt-24 items-center pr-4 pl-4 md:pr-0 md:pl-0">
  
  <div className="lg:col-span-2 mb-8 lg:mb-0">
    <h1 className="font-bold text-kintecs text-4xl">Our Vision</h1>
    <p className="mt-6">
      Pioneer in the world of designing and manufacturing of innovative, operable architectural solutions that enhance people's quality of life, connect them with nature and provide adaptable, dynamic and flexible spaces for diverse applications, Motion in Architecture.
    </p>
  </div>

  <div className="lg:col-span-1">
    <img className="rounded-lg w-full" src="assets/vision.webp" alt="Our Vision" />
  </div>
  
</div>

<div className="mx-auto container grid grid-cols-1 lg:grid-cols-3 gap-x-16 pb-24 mt-24 items-center pr-4 pl-4 md:pr-0 md:pl-0">
  
  <div className="lg:col-span-1 mb-8 lg:mb-0">
    <img className="rounded-lg w-full" src="assets/mision.webp" alt="Our Mission" />
  </div>

  <div className="lg:col-span-2">
    <h1 className="font-bold text-kintecs text-4xl">Our Mission</h1>
    <p className="mt-6">
      Presenting new dynamics that assist in the development of architects in their projects that transform exterior spaces into interior spaces. To provide innovative solutions to designers, architects, businesses, individuals through our expertise and intensive research in structures in motion. To create a virtual and global network for researchers, architects, engineers and designers to share knowledge and discuss new ideas.
    </p>
  </div>

</div>

<div className="mx-auto container grid grid-cols-1 lg:grid-cols-3 gap-x-16 pb-24 mt-24 items-center pr-4 pl-4 md:pr-0 md:pl-0">
  
  <div className="lg:col-span-2">
    <h1 className="font-bold text-kintecs text-4xl">Policy</h1>
    <p className="mt-6">
      As LIBART Company, we work in continuous improvement and development efforts by meeting customer expectations at maximum level in line with our mission and vision. In this direction, we establish satisfaction-oriented relations with our customers and employees in order to expand the business volume and become a sought-after company.
    </p>
  </div>

  <div className="lg:col-span-1 mb-8 lg:mb-0">
    <img className="rounded-lg w-full" src="assets/policy.webp" alt="Policy" />
  </div>

</div>

            <Subscribe />

    </div>
  )
}

export default About