import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdMenu, MdClose, MdOutlineEventNote } from 'react-icons/md';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="relative">
      <div className="flex items-center h-[120px] mx-auto container justify-between">
        
  
        <Link to="/">
          <img className="w-[200px] pl-2 pr-2" src="/assets/logo.svg" alt="Kintecs Logo" />
        </Link>

       
        

    
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-black z-50 relative">
            {isMenuOpen ? <MdClose size={30} /> : <MdMenu size={30} />}
          </button>
        </div>

        <div className="hidden md:block">
        {/* <div className="flex gap-x-6 justify-end">
          <img className="h-9" src="/assets/brand-libart.svg" alt="Libart Logo" />
          <img className="h-9" src="/assets/brand-panora.svg" alt="Panora Logo" />
          <img className="h-9" src="/assets/solaglide-logo.svg" alt="Solaglide Logo" />
          
          <ul className="flex space-x-2">
            <li>EN</li>
            <li>|</li>
            <li>SR</li>
          </ul>
        </div> */}
          <ul className="flex font-bold text-base gap-x-4 items-center">
  <li><Link to="/products">Products</Link></li>
  <li><Link to="/projects">Projects</Link></li>
  <li><Link to="/videos">Videos</Link></li>
  <li><Link to="/catalogues">Catalogues</Link></li>
  <li><Link to="/about-us">About Us</Link></li>
  <li><Link to="/contact">Contact</Link></li>

  
  <li className="relative">

    <div className="absolute top-[-2.5rem] left-1/2 transform flex items-center gap-x-2 text-sm">
      <span className="font-bold">EN</span>
      <span>|</span>
      <span className="font-normal">SR</span>
    </div>

 
    <button className="bg-kintecs text-white px-4 py-2 rounded flex items-center gap-x-1 font-oswald font-bold text-sm">
      <MdOutlineEventNote />
      <Link to="/request-form">Request Form</Link>
    </button>
  </li>
</ul>
        </div>
      </div>

      {isMenuOpen && (
        <div className="absolute top-0 left-0 w-full h-screen bg-white z-40 flex flex-col items-center justify-center space-y-6">
          <ul className="text-2xl font-bold text-center">
            <li className="mb-4"><Link to="/products" onClick={toggleMenu}>Products</Link></li>
            <li className="mb-4"><Link to="/projects" onClick={toggleMenu}>Projects</Link></li>
            <li className="mb-4"><Link to="/videos" onClick={toggleMenu}>Videos</Link></li>
            <li className="mb-4"><Link to="/catalogues" onClick={toggleMenu}>Catalogues</Link></li>
            <li className="mb-4"><Link to="/about-us" onClick={toggleMenu}>About Us</Link></li>
            <li className="mb-4"><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
            <li className="mt-4">
              <button className="bg-kintecs text-white px-5 py-1 rounded font-oswald font-bold text-xl">
                <Link to="/request-form" onClick={toggleMenu}>Request Form</Link>
              </button>
            </li>
            <li><div className="flex gap-x-4 justify-center mt-12">
          <img className="h-7" src="/assets/brand-libart.svg" alt="Libart Logo" />
          <img className="h-7" src="/assets/brand-panora.svg" alt="Panora Logo" />
          <img className="h-7" src="/assets/solaglide-logo.svg" alt="Solaglide Logo" />
        </div></li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;