import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Autoplay } from 'swiper/modules'; 
import data from '../data/data.json';
import Products from '../components/Products';
import Subscribe from '../components/Subscribe';
import { Link } from 'react-router-dom';


const Home = () => {
  return (
    <div>
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      speed={3000}
      modules={[Autoplay, Pagination]}
      className="mySwiper w-full"
    >
      <SwiperSlide>
      <div className="relative z-50">
          <img className="w-full h-[30vh] lg:h-full md:h-full object-cover" src="/assets/slider/verdad.webp" alt="Verdad" /> 
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-80"></div>
          <div className="absolute bottom-0 left-0 w-full">
            <div className="container mx-auto text-left">
              <div className="bg-white bg-opacity-80 p-2 py-4 px-4 md:p-4 md:py-6 md:px-6 inline-block">
                <h1 className="text-kintecs font-bold text-2xl md:text-3xl font-oswald mb-1 md:mb-5">Verdad Mexican Restaurant</h1>
                <h2 className="text-kintecs text-xl md:text-2xl font-oswald font-light">Revolutionize Your Space!</h2>
              </div>
            </div>
          </div>
        </div>
  </SwiperSlide>
      <SwiperSlide>
      <div className="relative z-50">
      <img className="w-full h-[30vh] lg:h-full md:h-full object-cover " src="/assets/slider/sakhalin.webp" alt="Sakhalin" />
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-80"></div>
            <div className="absolute bottom-0 left-0 w-full ">
            <div className="container mx-auto text-left ">
            <div className="bg-white bg-opacity-80 p-2 py-4 px-4 md:p-4 md:py-6 md:px-6 inline-block">
                <h1 className="text-kintecs font-bold text-2xl md:text-3xl font-oswald mb-1 md:mb-5">Sakhalin Bodrum Restaurant</h1>
                <h2 className="text-kintecs text-xl md:text-2xl font-oswald font-light">Seaview Dining with Kinetic Telescopic Glass Railing</h2>
            </div>
            </div>
            </div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="relative z-50">
      <img className="w-full h-[30vh] lg:h-full md:h-full object-cover" src="/assets/slider/mccullough.webp" alt="Mccullough" />
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-80"></div>
            <div className="absolute bottom-0 left-0 w-full ">
            <div className="container mx-auto text-left ">
            <div className="bg-white bg-opacity-80 p-2 py-4 px-4 md:p-4 md:py-6 md:px-6 inline-block">
                <h1 className="text-kintecs font-bold text-2xl md:text-3xl font-oswald mb-1 md:mb-5">McCulloughs Villa - Kodiak Lake</h1>
                <h2 className="text-kintecs text-xl md:text-2xl font-oswald font-light">Enjoy Your Balcony</h2>
            </div>
            </div>
            </div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="relative z-50">
      <img className="w-full h-[30vh] lg:h-full md:h-full object-cover" src="/assets/slider/mado.webp" alt="Mado" />
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-80"></div>
            <div className="absolute bottom-0 left-0 w-full ">
            <div className="container mx-auto text-left ">
            <div className="bg-white bg-opacity-80 p-2 py-4 px-4 md:p-4 md:py-6 md:px-6 inline-block">
                <h1 className="text-kintecs font-bold text-2xl md:text-3xl font-oswald mb-1 md:mb-5">Mado Istmarina Shopping Center</h1>
                <h2 className="text-kintecs text-xl md:text-2xl font-oswald font-light">The Perfect Blend of Design and Functionality!</h2>
            </div>
            </div>
            </div>
      </div>
      </SwiperSlide>
    </Swiper>


      {/* YouTube Video */}
      <div className="mx-auto container mt-24 mb-24 gap-x-10 p-4 md:p-0">
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
    

    <div>
      <h1 className="font-oswald font-black text-kintecs text-2xl mb-5 text-center lg:text-left">
        JOIN US AS A PARTNER & REAP THE BENEFITS
      </h1>
      <p className="text-base text-kintecs font-regular mb-5">
        Libart’s Kinetic Telescopic Glass Railing is an innovative and premium solution not only enhances the appearance of cafes, restaurants, and other commercial spaces but also offers comfortable, openable features, ensuring a superior customer experience.
      </p>
      <p className="text-base text-kintecs font-regular mb-5">
        Become a business partner of the Libart's Kinetic Systems and offer this state-of-the-art product to your clients. Increase your revenue and build lasting relationships by providing a perfect solution in the market. Contact us for business partnerships at busdev@libart.com.
      </p>
      <h2 className="text-base text-kintecs font-bold">
        Partner with Libart Today – Elevate Your Business and Profit!
      </h2>
    </div>


    <div className="w-full h-full mt-8 lg:mt-0">
      <iframe className="w-full aspect-video" src="https://www.youtube.com/embed/PZJBkx8Mgos?si=vDpoiPwsvZFjLKD0" data-mce-fragment="1"></iframe>
    </div>
  
  </div>
</div>

    {/* Çözümler Kısmı */}
    <Products />

    {/* Projeler */}
    <div className="mx-auto container mt-24 p-4 md:p-0">
  <div className="flex justify-between">
    <h1 className="font-oswald font-bold text-kintecs text-4xl">Projects</h1>
    <Link to="/projects">
      <button className="text-white bg-kintecs rounded-lg py-3 px-4 text-base font-oswald font-bold">
        See All Projects
      </button>
    </Link>
  </div>
  
  <div className="w-full border-b border-kintecs mt-3"></div>

  <div className="mt-10">
    <Swiper
      breakpoints={{
        140: { slidesPerView: 1, spaceBetween: -40 }, 
        768: { slidesPerView: 2, spaceBetween: 20 }, 
        1024: { slidesPerView: 3, spaceBetween: 30 },
        1280: { slidesPerView: 4, spaceBetween: 30 }  
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      speed={5000}
      modules={[Autoplay, Pagination]}
      className="mySwiper"
    >
      {data.projects.slice(0, 6).map((projects, index) => (
        <SwiperSlide key={index}>
            <Link to={`/projects/${projects.slug}`}>
          <div className="relative group">
            <img
              className="w-[300px] h-[200px] object-cover mt-3 rounded transform transition-transform duration-500 group-hover:scale-110"
              src={projects.pictures[0]}
              alt={projects.projectName}
            />
          </div>
          <h2 className="font-oswald text-xl font-bold mt-3">{projects.projectName}</h2>
          <h3>{projects.location}</h3>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
</div>

    {/* Mail Bülten */}
    <Subscribe />

    


    </div>
  )
}

export default Home