import React from 'react'
import { Link } from 'react-router-dom'

const Products = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-x-20 container mx-auto py-12 pl-4 pr-4 md:pr-0 md:pl-0">


    <div className="">
      <img className="rounded-t-xl w-full" src="/assets/istanbul.webp" alt="İstanbul" />
      <h1 className="font-oswald text-4xl text-kintecs font-bold mt-4">Fixed Unit (KU-FC)</h1>
      <div className="flex mt-3 gap-x-4">
        <div className="w-7/12">
          <p className="text-base text-gray-700">Libart’s Fixed Kinetic Telescopic Glass Railing is an exceptional product that is ideal for a variety of settings, including commercial spaces and residential homes. This railing stands out as a great solution for those who are looking for high-performance, sturdy, durable, and visually appealing equipment.</p>
          <Link to="/products/ku-fc"><button className="text-white bg-kintecs px-6 py-3 font-oswald font-bold text-lg mt-4 rounded hover:bg-gray-500">Show Details</button></Link>
        </div>
        <div className="w-5/12">
          <img className="w-full h-auto rounded" src="/assets/kufc.webp" alt="KU-FC" />
        </div>
      </div>
    </div>
  
  
    <div className="">
      <img className="rounded-t-xl w-full" src="/assets/istanbul2.webp" alt="İstanbul" />
      <h1 className="font-oswald text-4xl text-kintecs font-bold mt-4">Portable Unit (KU-FB)</h1>
      <div className="flex mt-3 gap-x-4">
        <div className="w-7/12">
          <p className="text-base text-gray-700">Libart’s Portable Kinetic Telescopic Glass Railing with a flower box feature is a unique and attractive addition to any deck or balcony. This product has several benefits, making it a great investment for home-owners. It is easy to install and can be easily moved from one location to another.</p>
          <Link to="/products/ku-fb"><button className="text-white bg-kintecs px-6 py-3 font-oswald font-bold text-lg mt-4 rounded hover:bg-gray-500">Show Details</button></Link>
        </div>
        <div className="w-5/12">
          <img className="w-full h-auto rounded" src="/assets/kufb.webp" alt="KU-FC" />
        </div>
      </div>
    </div>
  
      </div>
  )
}

export default Products